<template>
    <div>
        <div style="width: 200px;height: auto;margin: 20px 0px 20px 20px;">
            <el-button @click="vipInit" type="primary">一键初始化</el-button>
        </div>
        <div v-if="user.isVip == 0" style="width: 300px;height: 40px;margin: 20px 0px 20px 20px;display: flex;">
            <div style="width: 220px;height: 40px;">
                <el-input v-model="orderNo" placeholder="输入订单号"></el-input>
            </div>
            <div style="width: 80px;height: 40px;margin-left: 10px;">
                <el-button @click="refund" type="primary">退款</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            orderNo: ''
        }
    },
    computed:{
        user() {
            return this.$store.state.user
        },
    },
    methods: {
        vipInit() {
            var that = this;
            that.utils.confirm("初始化不可恢复，确认？",function (){
                that.newApi.vipInit({}).then((res) => {
                    that.openLoading("初始化中，请等待....");
                    if(res.isSuccess == 1){
                        that.utils.sus(res.data);
                        //刷新当前页面
                        window.location.reload();
                    }
                })
            })
        },
        refund(){
            var that = this;
            if(that.orderNo == ''){
                that.utils.err("请输入订单号");
                return false;
            }
            that.utils.confirm("确定退款？",function (){
                that.newApi.refund({orderNo:that.orderNo}).then((res) => {
                    that.openLoading("退款中，请等待....");
                    if(res.isSuccess == 1){
                        that.orderNo = "";
                        that.utils.sus(res.data);
                    }
                })
            })
        }
    }
}
</script>
<style scoped>

</style>